@import "../../../assets/styles/main";

.Partners {
  .contentbox .section .right-side .text-box.cover-image {
    margin-top: -100px;
  }
  .shareholders {
    display: flex;
    flex-wrap: wrap;

    .shareholder-wrapper {
      flex: 1;
      flex-basis: 25%;
      height: 180px;
      display: flex;
      padding: 10px;
      box-sizing: border-box;

      &.join {
        flex: 1;
        .shareholder {
          background: $font-color;

          h3 {
            font-family: $font-header;
            font-weight: normal;
            font-size: 15px;
            color: white;
          }
        }
      }

      .shareholder {
        flex: 1;
        display: flex;
        padding: 5%;
        box-sizing: border-box;
        border: 1px solid #ececec;
        justify-content: center;
        align-items: center;
        text-align: center;
        text-decoration: none;

        .image {
          flex: 1;
          width: 100%;
          height: 100%;
          background-repeat: no-repeat;
          background-size: contain;
          background-position: center;
          filter: grayscale(1);
        }
      }
    }
  }
}
