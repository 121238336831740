body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f1f2f3;
  overflow-y: scroll;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  color: #e30613;
}

@font-face {
  font-family: "Kelson Regular";
  font-style: normal;
  font-weight: normal;
  src: local("Kelson Regular"),
    url("assets/fonts/kelson-cufonfonts-webfont/Armasen\ -\ Kelson.woff")
      format("woff");
}
