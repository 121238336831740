@import "../../../assets/styles/main";

.hotnews {
  min-height: 520px;
  display: flex;
  overflow: hidden;
  color: white;

  .left-side,
  .right-side {
    flex: 1;
    display: flex;
  }

  .left-side {
    .main-news {
      flex: 1;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      background-color: #526374;
      display: flex;
      align-items: flex-end;

      .shadow-box {
        width: 100%;
        box-sizing: border-box;
        position: relative;
        overflow: hidden;
        padding-left: 18%;
        padding-bottom: 30px;
        padding-right: 10px;
        padding-top: 10px;
        background: rgba(0, 0, 0, 0.2);
        display: flex;
        .blogpreview {
          .content {
            .content-title {
              .title {
                font-size: 21px;
                margin-bottom: 5px;
              }
            }

            .content-description {
              .description {
                font-size: 11px;
                @include max-line(2);
              }
            }

            .content-more {
              color: $font-color;

              .more {
                font-size: 12px;
              }

              .line {
                background: $border-strong-color;
              }
            }
          }
        }
      }
    }
  }

  .right-side {
    position: relative;
    overflow: hidden;
    .background {
      width: 102%;
      height: 102%;
      position: absolute;
      z-index: 0;
      top: -1%;
      left: -1%;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      background-color: #526374;
      background-image: url("../../../assets/images/home-background.jpg");
      filter: blur(11px) brightness(0.45);
    }

    .previews {
      position: relative;
      z-index: 1;
      flex: 1;
      display: flex;
      flex-direction: column;
      padding: 10px 0;

      .preview-wrapper {
        flex: 1;
        display: flex;
        padding: 5px 0;

        .hot-news-blog-preview {
          .content {
            .content-top {
              margin: 0;
            }
            .content-title {
              .title {
                margin: 0;
                margin-top: 10px;
                margin-bottom: 5px;
              }
            }
            .content-description {
              .description {
                color: #eee;
              }
            }
          }
        }
      }
    }
  }
}
