@import "../../../assets/styles/main";

.top {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 18px 0;
  position: relative;

  .current-date {
    position: absolute;
    left: 0;
    top: 0;
    padding: 32px 0;

    span {
      font-family: $font-normal;
      font-size: 11px;
    }
  }

  .logo {
    max-width: 100%;
    width: 355px;
    height: 110px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
}
