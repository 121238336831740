.scrollablelist {
  position: relative;

  .scrollable-box {
    padding: 0 40px;
    position: relative;
    z-index: 10;

    .item-wrapper {
      flex: 1;
      position: relative;
      overflow: hidden;
      & > .inner {
        display: flex;

        .item {
          display: flex;
          flex: 1;
        }
      }
    }
  }

  .bottom-box {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 30px;
    background: #f1f2f3;
  }
}
