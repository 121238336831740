.aboutus {
  .about-us-text-box {
    max-width: 552px;
  }

  .team {
    width: 100%;
    position: relative;
    overflow: hidden;
  }
}
