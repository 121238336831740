@mixin max-line($clamp) {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $clamp; /* number of lines to show */
  -webkit-box-orient: vertical;
}

.App.mp {
  --font-color: #a31641;
  --border-normal-color: #cd7394;
  --border-strong-color: #7d1737;
}

.App.al {
  --font-color: #f2913d;
  --border-normal-color: #ffc18b;
  --border-strong-color: #a6642b;
}

$font-normal: "Nunito";
$font-header: "Yeseva One", cursive;
$font-color: var(--font-color);
$border-normal-color: var(--border-normal-color);
$border-strong-color: var(--border-strong-color);

/** responsive */

/*
.menu {
    .options {
      width: 100%;
      margin-right: 0;
      justify-content: space-between;
    }
    .other {
      .Search {
        display: none;
      }
    }
  }
*/
