/* Portrait tablet to landscape and desktop */

@media (max-width: 1150px) {
  .leftheader {
    h2 {
      font-size: 33px;
    }
  }
}

@media (max-width: 1050px) {
  .leftheader {
    h2 {
      font-size: 28px;
    }
  }
}

@media (max-width: 950px) {
  .App {
    & > .inner,
    .footer > .inner,
    .menu.fixed {
      padding-left: 30px;
      padding-right: 30px;
    }
  }
  .leftheader {
    h2 {
      font-size: 24px;
    }
  }
}

@media (max-width: 900px) {
  .menu {
    .menu-inner {
      justify-content: space-between;
    }
    .other {
      flex: 0;
      .Search {
        display: none;
      }
      .social-medias {
        margin-left: 0;
      }
    }
  }

  .contentbox .section .right-side .text-box,
  .blog .contentbox .section .text-box .blog-box .blog-padding {
    padding-left: 35px;
    padding-right: 35px;
  }
}

@media (max-width: 850px) {
  .partners .partners-content {
    section h4 {
      &.collaboration-header {
        display: block;
      }

      &.partners-header,
      &.organizer-header,
      &.sponsors-header {
        display: none;
      }
    }

    .rects {
      flex-wrap: wrap;
    }
  }
}

@media (max-width: 800px) {
  .leftheader {
    display: none;
  }

  .contentbox .bottom {
    margin-left: 0;
  }

  .contentbox .section .right-side .image-wrapper .image {
    height: 300px;
  }

  .contentbox .bottom {
    padding: 0 50px;
  }
}

/* Landscape phone to portrait tablet */
@media (max-width: 740px) {
  .contentbox .section .right-side .image-wrapper .image {
    height: 280px;
  }
  .menu {
    .options {
      .menu-option {
        font-size: 12px;
      }
    }
  }
}

@media (max-width: 700px) {
  .Partners .partners-content section {
    &.organizers {
      flex: 1;
    }

    &.partners {
      flex: 3;

      .rect-wrapper {
        flex-basis: 33.333%;
      }
    }

    &.sponsors {
      flex: 3;

      .rect-wrapper {
        flex-basis: 33.333%;
      }
    }
  }
}

/* Landscape phones and down */
@media (max-width: 660px) {
  .App {
    & > .inner,
    .footer > .inner,
    .menu.fixed {
      padding-left: 25px;
      padding-right: 25px;
    }
  }

  .footer .inner section {
    .footer-bottom-content {
      flex-direction: column;
      .shareholder {
        border-right: none;
      }
    }
  }

  .hotnews {
    flex-direction: column;
  }

  .top {
    .current-date {
      display: none;
    }
  }

  .contentbox .section .right-side .text-box,
  .blog .contentbox .section .text-box .blog-box .blog-padding {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media (max-width: 620px) {
  .top {
    .logo {
      width: 300px;
    }
  }
  .menu {
    .options {
      .menu-option {
        &.basic {
          &:nth-child(n + 3) {
            display: none;
          }
        }

        &.more {
          display: block;
        }
      }
    }
  }
}

@media (max-width: 600px) {
  .empty-bottom {
    display: none;
  }

  .footer .inner section {
    &.footer-menu,
    &.footer-contact,
    &.footer-short {
      min-width: 33.33333%;
    }

    .footer-bottom-content {
      flex-direction: column;
      .shareholder {
        border-right: none;
      }
    }

    &.footer-newsletter {
      min-width: 100%;
      order: 5;
      margin-bottom: 15px;

      .footer-newsletter-inner {
        flex-direction: row;
        align-items: center;

        .social-media {
          flex: 1;
          justify-content: center;
        }

        .line {
          display: none;
        }
      }
    }
  }

  .lastBlogsSet {
    display: flex;
    flex-direction: column;

    .preview-wrapper {
      margin-bottom: 15px;

      .blogpreview {
        flex-direction: row !important;

        .content-top {
          margin-top: 7px !important;
        }
      }
    }
  }

  .contentbox .section .right-side .image-wrapper .image {
    height: 250px;
  }

  .footer {
    min-height: 300px;
  }
  .footer .green-map-wrapper {
    height: 300px;
    margin-top: 0;
    padding: 0;
  }

  .contact .form {
    flex-direction: column;

    .side {
      width: inherit;
    }
  }
}

@media (max-width: 500px) {
  .contentbox .section .right-side .image-wrapper .image {
    height: 200px;
  }

  .footer .inner section h4 {
    font-size: 10px;
  }

  .footer .inner section a,
  .footer .inner section span,
  .footer .inner section li {
    font-size: 7.5px;
  }

  .contentbox .section .right-side .text-box,
  .blog .contentbox .section .text-box .blog-box .blog-padding {
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    box-sizing: border-box;
  }
}

@media (max-width: 460px) {
  .menu {
    &.fixed {
      .other {
        align-items: flex-end;
        .mini-logo {
          display: flex;
        }
      }
    }
    .menu-inner {
      flex-direction: column-reverse;
    }
    .options {
      width: 100%;
      margin-right: 0;
      justify-content: space-between;

      .menu-option {
        &.basic {
          &:nth-child(n + 3) {
            display: -webkit-box;
          }
        }

        &.more {
          display: none;
        }
      }
    }

    .other {
      width: 100%;
      margin-bottom: 10px;

      .Search {
        display: flex;
        margin-right: 10px;
      }
      .social-medias {
      }
    }
  }
}

@media (max-width: 450px) {
  .Partners .partners-content section {
    h4 {
      font-size: 9px;
    }
  }
}

@media (max-width: 440px) {
  .contentbox .section .right-side .image-wrapper .image {
    height: 170px;
  }

  .blogpreview .content .content-title .title {
    font-size: 13px;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .App {
    & > .inner,
    .footer > .inner,
    .menu.fixed {
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  .menu {
    .options {
      .menu-option {
        font-size: 11.5px;
      }
    }
  }

  .blogpreview .content .content-description .description {
    -webkit-line-clamp: 3;
  }
}

@media (max-width: 420px) {
  .Partners .partners-content section {
    &.organizers {
      flex: 1;
    }

    &.partners {
      flex: 2;

      .rect-wrapper {
        flex-basis: 50%;
      }
    }

    &.sponsors {
      flex: 2;

      .rect-wrapper {
        flex-basis: 50%;
      }
    }
  }
}

@media (max-width: 410px) {
  .menu {
    .options {
      .menu-option {
        font-size: 13.5px;
        &.basic {
          &:nth-child(n + 3) {
            display: none;
          }
        }

        &.more {
          display: block;

          .menu-select {
            right: 0;
          }
        }
      }
    }
  }
}

@media (max-width: 400px) {
  .App {
    & > .inner,
    .footer > .inner,
    .menu.fixed {
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  .blogpreview .content .content-title .title {
    font-size: 12.5px;
    margin-top: 5px;
    margin-bottom: 5px;
  }
}

@media (max-width: 380px) {
  .App {
    & > .inner,
    .footer > .inner {
      padding-left: 5px;
      padding-right: 5px;
    }
  }

  .top {
    .logo {
      width: 270px;
    }
  }
}

@media (max-width: 360px) {
  .blogpreview .content .content-title .title {
    font-size: 11px;
    margin-top: 3px;
    margin-bottom: 3px;
  }

  .blogpreview .content .content-description .description {
    -webkit-line-clamp: 2;
  }

  .footer .inner section.footer-contact h3 {
    font-size: 11px;
  }
}

@media (max-width: 300px) {
  .App {
    & > .inner,
    .footer > .inner,
    .menu.fixed {
      padding-left: 3px;
      padding-right: 3px;
    }
  }

  .top {
    .logo {
      width: 240px;
      height: 90px;
    }
  }
}
