@import "../../../assets/styles/main";

.contact {
  .contentbox .section .right-side .text-box.cover-image {
    margin-top: -100px;
  }
  .contact-item {
  }
  .side {
    width: 49%;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    & > .wysiwyg {
      font-size: 11pt;
      border-bottom: 1px solid;
      display: flex;
      margin: 0;
      padding: 0;
      margin-bottom: 15px;
    }

    &.left {
    }

    &.right {
    }
  }
  .content {
    display: flex;
    justify-content: space-between;
  }
  .form {
    width: 100%;
    display: flex;
    justify-content: space-between;

    box-sizing: border-box;

    .side {
    }

    label.main {
      text-align: right;
    }

    label.main {
      flex: 1;
      font-size: 10px;
      margin-left: 5px;
      margin-right: 15px;
    }

    .box {
      flex: 3;
      display: flex;
      align-items: center;

      .apply-image {
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        background-image: url("../../../assets/images/apply.png");
        width: 100%;
        height: 100px;
      }

      .message-sent {
        display: flex;
        height: 26px;
        align-items: center;
        span {
          font-size: 9px;
        }
      }
    }

    .inputs {
      display: flex;
      flex-direction: column;

      .field {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 14px;

        &.area {
          align-items: flex-start;

          label.main {
            padding-top: 5px;
          }
        }

        input,
        textarea {
          flex: 1;
          background: #e5e5e5;
          border: 1.5px solid #777;
          font-size: 10px;
          font-family: $font-normal;
          padding: 3px 6px;
          box-sizing: border-box;
        }

        .radio-label {
          display: flex;
          justify-content: center;
          align-items: center;

          span {
            font-size: 10px;
            margin-top: 2px;
          }
        }

        .checkbox-label {
          display: flex;
          justify-content: center;
          align-items: center;
          span {
            font-size: 10px;
          }
        }

        textarea {
          resize: vertical;
          max-width: 100%;
          height: 100px;
        }
      }
    }

    .buttons {
      display: flex;

      button {
        background: #e5e5e5;
        border: 1px solid black;
        font-size: 9px;
        font-family: $font-normal;
        padding: 3px 9px;
        text-transform: uppercase;
        font-weight: bold;
        letter-spacing: 0.5px;
        margin-right: 8px;
        transition: 0.3s ease-out;

        &:hover {
          cursor: pointer;
        }

        &:disabled {
          background: #878787 !important;
          filter: grayscale(0.5);

          &:hover {
            cursor: not-allowed;
          }
        }

        &.main {
          background: $font-color;
          color: white;
        }
      }
    }
  }
}
