@import "../../../assets/styles/main";

.blogs {
  .blog-item {
    .sections {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      flex-direction: row;
      margin-bottom: 20px;
      gap: 5px;

      &.tile {
        justify-content: center;
        .section {
          width: 80px;
          height: 80px;
          flex-direction: column;
          text-transform: uppercase;

          span {
            -webkit-line-clamp: 2;
          }
        }
      }

      .section {
        flex-grow: 1;
        margin: 0;
        padding: 4px 10px;
        background: #e0e0e0;
        border-radius: 2px;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 5px;
        border: none;

        img {
          width: 30px;
          height: 30px;
        }

        span {
          @include max-line(1);
          font-size: 10px;
          color: #222;
          font-family: $font-normal;
        }

        &:hover {
          cursor: pointer;
        }

        &.selected {
          background: #a2224a;
          font-weight: bold;
          span {
            color: white;
          }
        }

        &.lvl2 {
          flex-basis: 30%;
        }
      }
    }

    .loading-box {
      height: 150px;
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .right-side {
    .content-top-image {
      .image {
        min-height: 270px;
      }
    }

    .blogs-previews {
      display: flex;
      flex-direction: column;
      gap: 8px;

      &.tile {
        flex-direction: row;
        flex-wrap: wrap;

        .blogs-blog {
          flex-direction: column;
          flex-basis: 48%;

          .blog-preview-image-wrapper {
            flex-basis: 200px;
          }

          .content {
            .content-top,
            .content-more,
            .content-tags {
              display: none;
            }
          }
        }
      }
    }
  }

  .blog-bottom {
    display: flex;
    text-align: center;
    justify-content: center;
  }
  .slide-content {
    .blogs-slide {
      flex: 0;

      .content-title {
        margin-bottom: 0;
        .title {
          font-size: 22px;
          letter-spacing: 1px;
          margin-bottom: 10px;
        }
      }

      .content-description {
        .description {
          @include max-line(2);
          font-size: 12px;
          margin-top: 0;
        }
      }
    }
  }

  .text-box {
    .blogs-blog {
      .content-more {
        .more {
          color: $font-color;
        }
        .line {
          background: $font-color;
        }
      }
    }
  }
}
